import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-power-list"></a><h2>Psionic Power List
    </h2>
    <a id="psychic-warrior-powers"></a><h3>PSYCHIC WARRIOR POWERS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="first"></a><a id="first-level-psychic-warrior-powers"></a><h5>1ST-LEVEL PSYCHIC WARRIOR POWERS </h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#astral-traveler" style={{
              "color": "rgb(87, 158, 182)"
            }}>Astral Traveler:</a></td>
          <td>Enable yourself or another to join an astral
caravan-enabled trip.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#biofeedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Biofeedback<sup>A</sup>:</a></td>
          <td>Gain DR 2/-.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#bite-of-the-wolf" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bite of the Wolf:</a></td>
          <td>Gain bite attack for 1d8 damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Burst:</a></td>
          <td>Gain +10ft. to speed this round.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#call-weaponry" style={{
              "color": "rgb(87, 158, 182)"
            }}>Call Weaponry<sup>A</sup>:</a></td>
          <td>Create temporary weapon.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#catfall" style={{
              "color": "rgb(87, 158, 182)"
            }}>Catfall<sup>A</sup>:</a></td>
          <td>Instantly save yourself from a fall. </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#chameleon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Chameleon:</a></td>
          <td>Gain +10 enhancement bonus on Hide checks.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
              "color": "rgb(87, 158, 182)"
            }}>Claws of the Beast<sup>A</sup>:</a></td>
          <td>Your hands become deadly claws.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#compression" style={{
              "color": "rgb(87, 158, 182)"
            }}>Compression<sup>A</sup>:</a></td>
          <td>You grow smaller.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#conceal-thoughts" style={{
              "color": "rgb(87, 158, 182)"
            }}>Conceal Thoughts:</a></td>
          <td>You conceal your motives.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#detect-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Psionics:</a></td>
          <td>You detect the presence of psionics.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dissipating-touch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissipating Touch<sup>A</sup>:</a></td>
          <td>Touch deals 1d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#distract" style={{
              "color": "rgb(87, 158, 182)"
            }}>Distract:</a></td>
          <td>Subject gets -4 on Listen, Search, Sense Motive, and
Spot checks.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#elfsight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Elfsight:</a></td>
          <td>Gain low-light vision, +2 bonus on Search and Spot
checks, and notice secret doors.
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empty-mind" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empty Mind:</a></td>
          <td>Gain +2 on Will saves until your next action.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#expansion" style={{
              "color": "rgb(87, 158, 182)"
            }}>Expansion<sup>A</sup>:</a></td>
          <td>Become one size category larger.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#float" style={{
              "color": "rgb(87, 158, 182)"
            }}>Float:</a></td>
          <td>Buoy yourself in water or other liquid.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#force-screen" style={{
              "color": "rgb(87, 158, 182)"
            }}>Force Screen<sup>A</sup>:</a></td>
          <td>Invisible disc provides +4 shield bonus to AC.
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#grip-of-iron" style={{
              "color": "rgb(87, 158, 182)"
            }}>Grip of Iron<sup>A</sup>:</a></td>
          <td>Your iron grip gives +4 bonus on grapple checks.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#hammer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hammer<sup>A</sup>:</a></td>
          <td>Melee touch attack deals 1d8/round.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#inertial-armor" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inertial Armor<sup>A</sup>:</a></td>
          <td>Tangible field of force provides you with +4 armor
bonus to AC.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#metaphysical-claw" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaphysical Claw<sup>A</sup>:</a></td>
          <td>Your natural weapon gains +1 bonus.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#metaphysical-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaphysical Weapon<sup>A</sup>:</a></td>
          <td>Weapon gains +1 bonus.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#my-light" style={{
              "color": "rgb(87, 158, 182)"
            }}>My Light<sup>A</sup>:</a></td>
          <td>Your eyes emit 20-ft. cone of light.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#precognition-defensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Defensive<sup>A</sup>:</a></td>
          <td>Gain +1 insight bonus to AC and saving throws.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#precognition-offensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Precognition, Offensive<sup>A</sup>:</a></td>
          <td>Gain +1 insight bonus on your attack rolls.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#prescience-offensive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prescience, Offensive<sup>A</sup>:</a></td>
          <td>Gain +2 insight bonus on your damage rolls.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#prevenom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prevenom<sup>A</sup>:</a></td>
          <td>Your claws gain a poison coating.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#prevenom-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prevenom Weapon<sup>A</sup>:</a></td>
          <td>Your weapon is mildly venomous.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#skate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Skate:</a></td>
          <td>Subject slides skillfully along the ground.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#stomp" style={{
              "color": "rgb(87, 158, 182)"
            }}>Stomp<sup>A</sup>:</a></td>
          <td>Subjects fall prone and take 1d4 nonlethal damage.</td>
        </tr>
        <tr>
          <td className="last-row"><a href="psionicPowersQtoW.html#synesthete" style={{
              "color": "rgb(87, 158, 182)"
            }}>Synesthete:</a></td>
          <td className="last-row">You receive one kind of sense when
another sense is stimulated.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersQtoW.html#thicken-skin" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thicken Skin<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>Gain
+1 enhancement bonus to your AC for 10 min./level.</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#vigor" style={{
              "color": "rgb(87, 158, 182)"
            }}>Vigor<sup>A</sup>:</a></td>
          <td style={{
            "verticalAlign": "top"
          }}>Gain 5 temporary hit
points.</td>
        </tr>
      </tbody>
    </table>
    <a id="second"></a><a id="second-level-psychic-warrior-powers"></a> <h5>2ND-LEVEL PSYCHIC WARRIOR POWERS </h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#animal-affinity" style={{
              "color": "rgb(87, 158, 182)"
            }}>Animal Affinity<sup>A</sup>:</a></td>
          <td>Gain +4 enhancement to one ability.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#body-adjustment" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Adjustment<sup>A</sup>:</a></td>
          <td>Heal 1d12 damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#body-equilibrium" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Equilibrium:</a></td>
          <td>You can walk on nonsolid surfaces.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#body-purification" style={{
              "color": "rgb(87, 158, 182)"
            }}>Body Purification<sup>A</sup>:</a></td>
          <td>Restore 2 points of ability damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#concealing-amorpha" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concealing Amorpha:</a></td>
          <td>Quasi-real membrane grants you concealment.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#darkvision-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Darkvision, Psionic:</a></td>
          <td>See 60 ft. in total darkness.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#detect-hostile-intent" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect Hostile Intent:</a></td>
          <td>You can detect hostile creatures within 30 ft. of you.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dimension-swap" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Swap<sup>A</sup>:</a></td>
          <td>You and an ally switch positions.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#dissolving-touch" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissolving Touch<sup>A</sup>:</a></td>
          <td>Your touch deals 4d6 acid damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dissolving-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dissolving Weapon<sup>A</sup>:</a></td>
          <td>Your weapon deals 4d6 acid damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empathic-transfer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Transfer<sup>A</sup>:</a></td>
          <td>Transfer another&rsquo;s wounds to yourself.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#energy-adaptation-specified" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Adaptation, Specified<sup>A</sup>:</a></td>
          <td>Gain resistance 10 to one energy type.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#feat-leech" style={{
              "color": "rgb(87, 158, 182)"
            }}>Feat Leech<sup>A</sup>:</a></td>
          <td>Borrow another&rsquo;s psionic or metapsionic feats.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#hustle" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hustle:</a></td>
          <td>Instantly gain a move action.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#levitate-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Levitate, Psionic:</a></td>
          <td>You move up and down, forward and back, via mental
support.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#painful-strike" style={{
              "color": "rgb(87, 158, 182)"
            }}>Painful Strike<sup>A</sup>:</a></td>
          <td>Your natural weapons deal an extra 1d6 nonlethal damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#prowess" style={{
              "color": "rgb(87, 158, 182)"
            }}>Prowess:</a></td>
          <td>Instantly gain another attack of opportunity.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#psionic-scent" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Scent:</a></td>
          <td>Gain the scent ability.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psionic-lions-charge" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic Lion's Charge<sup>A</sup>:</a></td>
          <td>You can make full attack in same round you charge.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#strength-of-my-enemy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Strength of My Enemy<sup>A</sup>:</a></td>
          <td>Siphon away your enemy&rsquo;s strength and grow
stronger.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#sustenance" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sustenance:</a></td>
          <td>You can go without food and water for one day.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#thought-shield" style={{
              "color": "rgb(87, 158, 182)"
            }}>Thought Shield<sup>A</sup>:</a></td>
          <td>Gain PR 13 against mind-affecting powers.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#wall-walker" style={{
              "color": "rgb(87, 158, 182)"
            }}>Wall Walker:</a></td>
          <td>Grants ability to walk on walls and ceilings.</td>
        </tr>
      </tbody>
    </table>
    <a id="third"></a><a id="third-level-psychic-warrior-powers"></a><h5>3RD-LEVEL PSYCHIC WARRIOR POWERS </h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#claws-of-the-vampire" style={{
              "color": "rgb(87, 158, 182)"
            }}>Claws of the Vampire:</a></td>
          <td>Heal half of your claw&rsquo;s base damage.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#concealing-amorpha-greater" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concealing Amorpha, Greater:</a></td>
          <td>Quasi-real membrane grants you total concealment.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#danger-sense" style={{
              "color": "rgb(87, 158, 182)"
            }}>Danger Sense:</a></td>
          <td>Gain +4 bonus against traps.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dimension-slide" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Slide<sup>A</sup>:</a></td>
          <td>Teleports you very short distance.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#duodimensional-claw" style={{
              "color": "rgb(87, 158, 182)"
            }}>Duodimensional Claw:</a></td>
          <td>Increases your natural weapon&rsquo;s threat range.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#ectoplasmic-form" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ectoplasmic Form:</a></td>
          <td>You gain benefits of being insubstantial and can fly
slowly.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#empathic-feedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Feedback<sup>A</sup>:</a></td>
          <td>When you are hit in melee, your attacker takes damage.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#empathic-transfer-hostile" style={{
              "color": "rgb(87, 158, 182)"
            }}>Empathic Transfer, Hostile<sup>A</sup>:</a></td>
          <td>Your touch transfers your hurt to another.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#escape-detection" style={{
              "color": "rgb(87, 158, 182)"
            }}>Escape Detection:</a></td>
          <td>You become difficult to detect with clairsentience
powers.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#evade-burst" style={{
              "color": "rgb(87, 158, 182)"
            }}>Evade Burst<sup>A</sup>:</a></td>
          <td>You take no damage from a burst on a successful Reflex
save.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#exhalation-of-the-black-dragon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Exhalation of the Black Dragon<sup>A</sup>:</a></td>
          <td>Your acid breath deals 3d6 damage to a close target.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#graft-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Graft Weapon:</a></td>
          <td>Your hand is replaced seamlessly by your weapon.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#keen-edge-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Keen Edge, Psionic:</a></td>
          <td>Doubles normal weapon&rsquo;s threat range.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mental-barrier" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mental Barrier<sup>A</sup>:</a></td>
          <td>Gain +4 deflection bonus to AC until your next action.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#ubiquitous-vision" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ubiquitous Vision:</a></td>
          <td>You have all-around vision. </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#vampiric-blade" style={{
              "color": "rgb(87, 158, 182)"
            }}>Vampiric Blade:</a></td>
          <td>You heal half of your base weapon damage.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth"></a><a id="fourth-level-psychic-warrior-powers"></a><h5>4TH-LEVEL PSYCHIC WARRIOR POWERS </h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#claw-of-energy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Claw of Energy</a></td>
          <td>Your claws deal additional energy damage.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dimension-door-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dimension Door, Psionic:</a></td>
          <td>Teleports you short distance. </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#energy-adaptation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Energy Adaptation<sup>A</sup>:</a></td>
          <td>Your body converts energy to harmless light.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#freedom-of-movement-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Freedom of Movement, Psionic:</a></td>
          <td>You cannot be held or otherwise rendered immobile.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#immovability" style={{
              "color": "rgb(87, 158, 182)"
            }}>Immovability:</a></td>
          <td>You are almost impossible to move and gain DR 15/-.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#inertial-barrier" style={{
              "color": "rgb(87, 158, 182)"
            }}>Inertial Barrier:</a></td>
          <td>Gain DR 5/-.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psychic-vampire" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychic Vampire:</a></td>
          <td>Touch attack drains 2 power points/level from foe. </td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#steadfast-perception" style={{
              "color": "rgb(87, 158, 182)"
            }}>Steadfast Perception:</a></td>
          <td>Gain immunity to illusory effects, +6 bonus on Spot and
Search checks.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#truevenom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Truevenom:</a></td>
          <td>Your natural weapons are covered in horrible poison.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersQtoW.html#truevenom-weapon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Truevenom Weapon:</a></td>
          <td>Your weapon is horribly poisonous. </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#weapon-of-energy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Weapon of Energy:</a></td>
          <td>Weapon deals additional energy damage.</td>
        </tr>
      </tbody>
    </table>
    <a id="fifth"></a><a id="fifth-level-psychic-warrior-powers"></a><h5>5TH-LEVEL PSYCHIC WARRIOR POWERS </h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#adapt-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Adapt Body:</a></td>
          <td>Your body automatically adapts to hostile environments.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#catapsi" style={{
              "color": "rgb(87, 158, 182)"
            }}>Catapsi<sup>A</sup>:</a></td>
          <td>Psychic static inhibits power manifestation.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#metaconcert" style={{
              "color": "rgb(87, 158, 182)"
            }}>Metaconcert<sup>A</sup>:</a></td>
          <td>Mental concert of two or more increases the total power
of the participants.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#oak-body" style={{
              "color": "rgb(87, 158, 182)"
            }}>Oak Body<sup>A</sup>:</a></td>
          <td>Your body becomes as hard as oak.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersGtoP.html#psychofeedback" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psychofeedback:</a></td>
          <td>Boost Str, Dex, or Con at the expense of one or more
other scores.</td>
        </tr>
      </tbody>
    </table>
    <a id="sixth"></a><a id="sixth-level-psychic-warrior-powers"></a><h5>6TH-LEVEL PSYCHIC WARRIOR POWERS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Power Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#breath-of-the-black-dragon" style={{
              "color": "rgb(87, 158, 182)"
            }}>Breath of the Black Dragon<sup>A</sup>:</a></td>
          <td>Breathe acid for 11d6 damage.
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#dispelling-buffer" style={{
              "color": "rgb(87, 158, 182)"
            }}>Dispelling Buffer:</a></td>
          <td>You are buffered from one dispel psionics effect.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#form-of-doom" style={{
              "color": "rgb(87, 158, 182)"
            }}>Form of Doom<sup>A</sup>:</a></td>
          <td>You transform into a frightening tentacled beast.</td>
        </tr>
        <tr>
          <td><a href="psionicPowersGtoP.html#mind-blank-personal" style={{
              "color": "rgb(87, 158, 182)"
            }}>Mind Blank, Personal:</a></td>
          <td>You are immune to scrying and mental effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersQtoW.html#suspend-life" style={{
              "color": "rgb(87, 158, 182)"
            }}>Suspend Life:</a></td>
          <td>Put yourself into a state akin to suspended animation.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      